import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import SubMenu from './SubMenu';
import { useTranslate, MenuItemLink } from 'react-admin';
import {  useSelector } from 'react-redux';
import inflection from 'inflection';
import DefaultIcon from '@material-ui/icons/ViewList';

const translatedResourceName = (resource, translate) => {
  if(!resource) return 'undefined';
  return translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
        resource.options && resource.options.label
            ? translate(resource.options.label, {
              smart_count: 2,
              _: resource.options.label,
            })
            : inflection.humanize(inflection.pluralize(resource.name)),
  });
};



const stripTrailingSlash = str => {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1);
  }
  return str;
};


const createLabel = (menu, translate) => {
  return menu.label ? translate(menu.label) : translatedResourceName(menu.resource, translate);
};

const createLinkTo = (menu,url) => {
  if(menu.to) return  menu.withoutDashboard
      ? `/${menu.to}`
      : `${url}/${menu.to}`;
  if(menu.resource) return `/${menu.resource.name}`;
  return '/';
};

const createIcon = (menu) => {
  return menu.leftIcon ? <menu.leftIcon/> : menu.resource && menu.resource.icon ? <menu.resource.icon /> : <DefaultIcon />;
};

export default React.memo(function SidebarMenu({
  singleOption,
  submenuStyle,
  submenuColor, dense, onMenuClick,handleToggle,isOpen,
  ...rest
}) {
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const { key, label, leftIcon, children } = singleOption;

  if (children) {
    return (
        <SubMenu
            key={key}
            handleToggle={() => handleToggle(key)}
            isOpen={isOpen}
            sidebarIsOpen={open}
            name={label}
            icon={leftIcon}
            dense={dense}
        >
          {children.map(child => {
            if(!child) return null;
            return (
                <ChildMenuItemLink key={child.key || child.resource ? child.resource.name: ''} menu={child} dense={dense} onMenuClick={onMenuClick}/>
            );
          })}
        </SubMenu>
    );
  }

  return (
      <ChildMenuItemLink menu={singleOption} dense={dense} onMenuClick={onMenuClick}/>
  );
});

const ChildMenuItemLink = React.memo(({menu, dense, onMenuClick}) => {
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  let match = useRouteMatch();
  const url = stripTrailingSlash(match.url);
  const translate = useTranslate();

  return (
      <MenuItemLink
          to={createLinkTo(menu, url)}
          primaryText={createLabel(menu, translate)}
          leftIcon={createIcon(menu)}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
      />
  );
});
