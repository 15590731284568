import {shallowEqual, useSelector} from 'react-redux';
import {getReferenceResource} from 'react-admin';

export const useResourceProps = (resourceName) => {
    // console.log(resourceName);
    return useSelector((state) => {
        var resource = getReferenceResource(state, {
            reference: resourceName,
        });
        return resource ? resource.props : null;
    }, shallowEqual);
};

export const useResourceOptions = (resourceName) => {
    const resourceProps = useResourceProps(resourceName);
    return resourceProps ? resourceProps.options : null;
};
