import React, { Fragment } from 'react';
import {

    BulkDeleteButton,
    // NumberField,
} from 'react-admin';
export const BulkActionButtons = props => {
    const { operations} = props;
    return (
        <Fragment>
            {/*{writableFields.repricerActive && <RepricerToggleBulkButton label="Activate Repricer" repricerActive={true} {...props} />}*/}
            {/*{writableFields.repricerActive && <RepricerToggleBulkButton label="De-activate Repricer" repricerActive={false} {...props} />}*/}
            {operations.delete && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};
