import React, { Fragment }   from "react";
import {
    Datagrid
} from 'react-admin';
import {List} from "../Common/List";
import FieldGuesser from 'UI/Common/ApiPlateform/FieldGuesser';
import InlineEditButton from 'UI/Common/InlineEditButton';
import {EditFinal} from "./Edit";
import {useResourceOptions} from "../../hooks";
import {EnableToggleButton} from "../Common/EnableDisableButton";
import {DefaultListProps} from "../Common/DefaultListProps";

export const ListPlain = ({...props }) => {
    const { readableFields, operations } = useResourceOptions(props.resource);
    if(!operations || !operations.list) return null;
    return (
        <Fragment>
            <List {...DefaultListProps({operations})} {...props}>
                <Datagrid>
                    {/*{readableFields.code && <FieldGuesser label={'code'} source={'code'}/>}*/}
                    {readableFields.name && <FieldGuesser label={'name'} source={'name'}/>}
                    <EnableToggleButton/>

                    <InlineEditButton EditComponent={EditFinal}/>
                    {/*<EditButton/>*/}

                </Datagrid>
            </List>
        </Fragment>
    );
};

export const ListFinal = ListPlain;
