import React from 'react';
import LabelIcon from '@material-ui/icons/Label';
import SettingsIcon from '@material-ui/icons/Settings';
import BugReportIcon from '@material-ui/icons/BugReport';
import {
  user,
  usergroup,
  adsprofile,
  adsportfolio,
  adscampaign,
  businessReportByChild,
  businessReportByParent,
} from "../resources";

const options = [
  {
    key: 'ads',
    label: 'Ads',
    leftIcon: <LabelIcon/>,
    children: [

      {
        resource: adsprofile.name,
      },
      {
        resource: adsportfolio.name,
      },
      {
        resource: adscampaign.name,
      },
      {
        resource: businessReportByChild.name,
      },
      {
        resource: businessReportByParent.name,
      },
    ],
  },

  {
    key: 'setting-menu',
    label: 'Settings',
    leftIcon: <SettingsIcon/>,
    children: [
      // {
      //   resource: adsaccount.name,
      // },
      // {
      //   resource: supplier.name,
      // },
      // {
      //   resource: supplierAttributes.name,
      // },
      // {
      //   resource: supplierSetting.name,
      // },

      // {
      //   resource: channel.name,
      // },
      // {
      //   resource: channelSetting.name,
      // },
      {
        resource: user.name,
      },
      {
        resource: usergroup.name,
      },
    ],
  },

  {
    key: 'log-menu',
    label: 'Logs',
    leftIcon: <BugReportIcon/>,
    children: [
      // {
      //   resource: cronLog.name,
      // },
      // {
      //   resource: channelRequestLog.name,
      // },
      // {
      //   resource: 'amazonfeedreports',
      // },

    ],
  },
];
export default options;
