import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {
  BooleanField,
  ChipField,
  DateField,
  EmailField,
  NumberField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  UrlField,
} from 'react-admin';

import {useResourceOptions} from "hooks";
import {SchemaAnalyzerContext} from "@api-platform/admin";

const isFieldSortable = (field, resourceSchema) => {
  return (
      resourceSchema.parameters.filter(
          parameter => parameter.variable === field.name,
      ).length > 0 &&
      resourceSchema.parameters.filter(
          parameter => parameter.variable === `order[${field.name}]`,
      ).length > 0
  );
};

const renderField = (field, {schemaAnalyzer, ...props}) => {

  if (null !== field.reference) {
    if (1 === field.maxCardinality) {
      return (
          <ReferenceField reference={field.reference.name} {...props} allowEmpty>
            <ChipField source={schemaAnalyzer.getReferenceNameField(field.reference)} />
          </ReferenceField>
      );
    }

    const referenceNameField = schemaAnalyzer.getReferenceNameField(field.reference);
    return (
        <ReferenceArrayField reference={field.reference.name} {...props}>
          <SingleFieldList>
            <ChipField source={referenceNameField} key={referenceNameField} />
          </SingleFieldList>
        </ReferenceArrayField>
    );
  }

  const fieldType = schemaAnalyzer.getFieldType(field);

  switch (fieldType) {
    case 'email':
      return <EmailField {...props} />;

    case 'url':
      return <UrlField {...props} />;

    case 'integer':
    case 'float':
      return <NumberField {...props} />;

    case 'boolean':
      return <BooleanField {...props} />;

    case 'date':
    case 'dateTime':
      return <DateField {...props} />;

    default:
      return <TextField {...props} />;
  }
};

export const FieldGuesser = ({ ...props}) => {
  const {resourceSchema, fields} = useResourceOptions(props.resource);
  const schemaAnalyzer = useContext(SchemaAnalyzerContext);

  const field = fields[props.source];
  if (!field) {
    // console.error(
    //     `Field "${props.source}" not present inside the api description for the resource "${props.resource}"`,
    // );

    return <TextField {...props} />;
  }

  return renderField(field, {
    schemaAnalyzer: schemaAnalyzer,
    sortable: isFieldSortable(field, resourceSchema),
    ...props,
  });
};


FieldGuesser.propTypes = {
  source: PropTypes.string.isRequired,
};

export default FieldGuesser;
