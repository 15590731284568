import React from "react";
import {
    NumberField,
    ReferenceField,NumberInput
} from 'react-admin';

export const Currency = ({source, ...props}) =>{
    const {record} = props;
    return (
        <ReferenceField source="store" reference="stores" link={false} {...props}>
            {
                <CurrencyFinal originalRecord={record} originalSource={source}/>
            }
        </ReferenceField>
    )
};

export const CurrencyFinal = ({record,...props}) => {
    var {originalRecord,originalSource,locales} = props;
    if(!locales){
        if(record.currency === 'GBP') locales = 'en-GB';
        else locales = 'fr-FR';
    }
    return (
        <NumberField record={originalRecord} source={originalSource} locales={locales}
                     options={{style: 'currency', currency: record.currency || 'EUR'}} />
    )
};


export const CurrencyField = (props) => {
    var {record,source,locales} = props;
    if(!locales){
        if(record.currency === 'GBP') locales = 'en-GB';
        // if(record.originalTotalCurrencyCode === 'AED') locales = 'ar-AE';
        else locales = 'en-US';
    }

    const newRecord = {};
    if(record[source]) newRecord[source] = record[source]*0.01;

    return (
        <NumberField {...props} record={newRecord} locales={locales} options={{style: 'currency', currency: record.currency || record.originalTotalCurrencyCode || 'USD'}} />
    )
};

export const CurrencyInputField = (props) => {
    return (
        <NumberInput format={v => v/100} parse={v => v*100} {...props} />
    )
};

export const PercentField = (props) => {
    var {record,source,locales} = props;
    const newRecord = {};
    if(record[source]) newRecord[source] = record[source]*0.01;

    return (
        <NumberField {...props} record={newRecord} locales={locales} options={{style: 'percent'}} />
    )
};
